import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";


const Button = ({ content, newPage, link, secondary, secondary2 }) => {
    const MotionLink = motion.create(Link);

    return (
        <MotionLink
            to={link}
            className={"button" + (secondary ? " secondary" : secondary2 ? " secondary2" : "")}
            whileTap={{ scale: 1.02 }}
            whileHover={{ scale: 1.08 }}
            transition={{ type: "spring", stiffness: 300, damping: 15 }}
            target={newPage ? "_blank" : "_self"}
            onClick={() => {
                if (!newPage) {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                };
            }}
        >
            {content}
        </MotionLink>
    );
};

export default Button;