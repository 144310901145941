import { motion } from "framer-motion";

export function Card({ pair, title, description, image, alt }) {
    return (
        <motion.div
            className={"details" + (pair ? " pair" : "")}
            initial="offscreen" whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
                offscreen: { y: 80, opacity: 0 },
                onscreen: {
                    y: 0, opacity: 1,
                    transition: { type: "spring", bounce: 0.2, duration: 0.7 }
                }
            }}
        >
            <div className="left-img-container">
                <motion.img
                    whileTap={{ scale: 1.1, rotate: pair ? 2 : -2 }}
                    className="left-img" src={image} alt={alt}
                />
            </div>
            <div className="right">
                <h3 className="section-subtitle">{title}</h3>
                <p>{description}</p>
            </div>
        </motion.div>
    )
};

export function AnimSection({ content }) {
    return (
        <motion.div
            className="content"
            initial="offscreen" whileInView="onscreen"
            viewport={{ once: true, amount: 0.1 }}
            variants={{
                offscreen: { y: 60, opacity: 0 },
                onscreen: {
                    y: 0, opacity: 1,
                    transition: { type: "spring", bounce: 0.2, duration: 0.7 }
                }
            }}>
            {content}
        </motion.div>
    )
};