import Button from "../components/Button";
import { AnimSection } from "../components/FadeAnim";

function App() {
    return (
        <div className="backlight">
            <AnimSection content={<>
                <section className="home">
                    <div className="home-content">
                        <h1 className="text-one not-found-title">On dirait que tu t'es perdu 👀</h1>
                        <Button secondary2="true" content={"Retourner à la page d'accueil ?"} link="/"></Button>
                    </div>
                </section></>
            }>
            </AnimSection>
        </div>
    );
}

export default App;