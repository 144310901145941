import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './style.css';
import Home from "./pages/home";
import Commands from "./pages/commandes";
import Terms from "./pages/termes";
import NotFound from "./pages/notfound";

function App() {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/commandes" element={<Commands />} />
                <Route path="/termes" element={<Terms />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;