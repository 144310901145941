function App() {
    return (
        <>
            <section className="reverse-color backlight2">
                <div className="content" id="header-section">
                    <div className="commands-title">
                        <h1>Conditions générales de RockyBot</h1>
                        <em>Dernière mise à jour : 14 Octobre 2024</em>

                        <p>En utilisant RockyBot, vous acceptez d'être lié par les termes et conditions suivants.
                            <br />Ces termes peuvent être modifiés à tout moment et sans préavis, et votre utilisation continue
                            de RockyBot après de telles modifications constitue votre acceptation des nouveaux termes.
                        </p>
                    </div>

                    <div className="commands-text">
                        <h1 className="section-subtitle">Conditions d'Utilisation</h1>
                    </div>

                    <ol>
                        <li><h2>Acceptation des termes</h2>
                            <p>En accédant et en utilisant RockyBot, vous acceptez d'être lié par les présents termes d'utilisation. Ces
                                termes peuvent être modifiés à tout moment et sans préavis, et votre utilisation continue de RockyBot
                                après de telles modifications constitue votre acceptation des nouveaux termes.</p>
                        </li>

                        <li><h2>Utilisation de RockyBot</h2></li>
                        <p>RockyBot est destiné à être utilisé sur la plateforme Discord. Vous acceptez d'utiliser RockyBot
                            conformément aux règles de Discord et à toutes les lois et réglementations locales, nationales et
                            internationales applicables.
                        </p>

                        <li><h2>Contenu généré par l'utilisateur</h2></li>
                        <p>Vous êtes responsable de tout contenu que vous soumettez, publiez ou affichez via RockyBot. Vous acceptez
                            de ne pas utiliser RockyBot pour envoyer ou transmettre des contenus illégaux, nuisibles, menaçants,
                            abusifs, harcelants, diffamatoires, vulgaires, obscènes, haineux, ou autrement répréhensibles.</p>

                        <li><h2>Collecte et utilisation des données</h2></li>
                        <p>RockyBot collecte certaines informations, comme indiqué ci-dessous dans la politique de confidentialité.
                            En
                            utilisant RockyBot, vous consentez à cette collecte et à cette utilisation de données.</p>

                        <li><h2>Limitation de responsabilité</h2></li>
                        <p>RockyBot est fourni "tel quel" et "selon disponibilité". Nous ne garantissons pas que RockyBot sera
                            ininterrompu, sans erreur ou sécurisé. Vous utilisez RockyBot à vos propres risques. Dans toute la
                            mesure permise par la loi, nous déclinons toute responsabilité en cas de dommages directs, indirects,
                            accessoires, consécutifs ou spéciaux découlant de l'utilisation ou de l'incapacité à utiliser RockyBot.
                        </p>

                        <li><h2>Indemnisation</h2></li>
                        <p>Vous acceptez d'indemniser et de dégager de toute responsabilité RockyBot, ses créateurs, développeurs,
                            et affiliés, contre toute réclamation, perte, dommage, responsabilité, et frais (y compris les
                            honoraires d'avocats) découlant de votre utilisation de RockyBot ou de votre violation de ces termes.
                        </p>

                        <li><h2>Modification des termes</h2></li>
                        <p>Nous nous réservons le droit de modifier ces termes à tout moment. Nous vous informerons de
                            toute modification importante en affichant les nouveaux termes sur notre <a className="ca" href="https://discord.gg/r3VvvSnFCs" target="_blank" rel="noreferrer">serveur Discord</a>.
                            Votre utilisation continue de RockyBot après de telles modifications constitue votre acceptation des
                            nouveaux termes.
                        </p>
                    </ol>

                    <div className="commands-text">
                        <h1 className="section-subtitle">Politique de Confidentialité</h1>
                    </div>

                    <p>Nous, chez RockyBot, nous engageons à protéger votre vie privée. Cette politique de confidentialité
                        explique
                        comment nous recueillons, utilisons et protégeons les informations que vous nous fournissez lorsque vous
                        utilisez notre bot Discord, RockyBot. En utilisant RockyBot, vous consentez à la collecte et à
                        l'utilisation de vos informations conformément à cette politique.</p>
                    <p></p>
                    <ol>
                        <li><h2>Collecte des informations</h2></li>
                        <p>RockyBot collecte les types d'informations suivants pour fournir ses fonctionnalités :</p>
                        <ul>
                            <li><strong>Identifiants Discord</strong> : Nous collectons des informations sur les serveurs, les
                                salons, les rôles et les utilisateurs de Discord afin de faciliter les interactions et les
                                fonctionnalités du bot dans ces environnements.</li>
                            <li><strong>Textes, identifiants, images et liens externes</strong> : RockyBot peut recueillir les
                                messages,
                                identifiants, images et liens externes que vous fournissez en utilisant ses commandes ou systèmes
                                pour
                                exécuter ses fonctionnalités.</li>
                            <li><strong>Statistiques d'utilisation anonymes</strong> : Nous recueillons des données anonymes sur
                                l'utilisation de RockyBot à des fins d'analyse et d'amélioration de nos services. Ces statistiques
                                ne
                                permettent pas d'identifier personnellement un utilisateur.</li>
                        </ul>
                        <li><h2>Utilisation des informations</h2></li>
                        <p>Nous utilisons les informations collectées par RockyBot dans les buts suivants :</p>
                        <ul>
                            <li>Fournir, maintenir et améliorer les fonctionnalités et la performance de RockyBot.</li>
                            <li>Répondre aux demandes de support et aux questions des utilisateurs.</li>
                            <li>Analyser l'utilisation de RockyBot afin d'identifier les tendances et d'améliorer nos services.</li>
                        </ul>
                        <li><h2>Partage des informations</h2></li>
                        <p>Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :</p>
                        <ul>
                            <li>Lorsque cela est nécessaire pour répondre à une obligation légale ou pour protéger nos droits, notre
                                propriété ou notre sécurité, ou ceux des autres.</li>
                            <li>Avec votre consentement explicite.</li>
                        </ul>
                        <li><h2>Sécurité des informations</h2></li>
                        <p>Nous prenons des mesures raisonnables pour protéger les informations collectées par RockyBot contre la
                            perte,
                            l'utilisation abusive, l'accès non autorisé, la divulgation, l'altération ou la destruction.</p>
                        <li><h2>Accès et contrôle de vos informations</h2></li>
                        <p>Vous pouvez accéder à vos informations personnelles collectées par RockyBot et demander leur mise à jour
                            ou leur suppression en nous contactant à <a className="ca"
                                href="mailto:hello@rockybot.pro">hello@rockybot.pro</a>.
                            Nous répondrons à votre demande dans les délais les plus brefs possibles.</p>

                        <br />
                    </ol>
                    <h2>Nous contacter</h2>
                    <p>Si vous avez des questions concernant ces termes de confidentialité ou les pratiques de RockyBot,
                        veuillez nous contacter à <a className="ca" href="mailto:hello@rockybot.pro">hello@rockybot.pro</a> ou
                        via notre <a className="ca" href="https://discord.gg/r3VvvSnFCs" target="_blank" rel="noreferrer">serveur Discord</a>.
                    </p>
                </div>
            </section>
        </>
    );
}

export default App;