import React from "react";
import { Link, useLocation } from "react-router-dom";

const NewLink = ({ to, content, newPage, noRelink = false }) => {
    const path = useLocation().pathname;

    return (
        <Link
            className={noRelink ? "" : ("relink" + (path === to ? " active" : ""))}
            to={to} target={newPage ? "_blank" : "_self"}
            onClick={() => {
                if (!newPage) {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                };
            }}>
            {content}
        </Link>
    );
};

export default NewLink;