import React from "react";
import NewLink from "./NewLink";

export default function Navbar() {
    return (
        <header className="nav-container">
            <nav className="navbar">
                <NewLink to="./" content={<img src="./RockyTitle.png" alt="Logo de RockyBot" />} noRelink="true" />
                <ul className="links">
                    <li><NewLink content="Commandes" to="/commandes" /></li>
                    <li><NewLink content="Invite-moi" newPage="true" to="https://discord.com/api/oauth2/authorize?client_id=894818420258922546" /></li>
                </ul>
            </nav>
        </header>
    );
};