import React from "react";
import { AnimatePresence, motion } from "framer-motion";

const AccordionContext = React.createContext({});
const useAccordion = () => React.useContext(AccordionContext);

function Accordion({ children, defaultIndex }) {
    const [activeIndex, setActiveIndex] = React.useState(defaultIndex);

    function onChangeIndex(index) {
        setActiveIndex((currentActiveIndex) => {
            return index === activeIndex ? -1 : index;
        });
    }

    return React.Children.map(children, (child, index) => {
        const isActive = activeIndex === index;

        return (
            <AccordionContext.Provider value={{ isActive, index, onChangeIndex }}>
                {child}
            </AccordionContext.Provider>
        );
    });
}

function AccordionItem({ children }) {
    return <div className="AccordionItem">{children}</div>;
}

function AccordionHeader({ children }) {
    const { isActive, index, onChangeIndex } = useAccordion();

    return (
        <motion.div
            className={`AccordionHeader no-select ${isActive ? "active" : ""}`}
            onClick={() => onChangeIndex(index)}
        >
            {children}
        </motion.div>
    );
}

function AccordionPanel({ children }) {
    const { isActive } = useAccordion();

    return (
        <AnimatePresence initial={false}>
            {isActive && (
                <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: "auto" }}
                    exit={{ height: 0 }}
                    transition={{ type: "spring", duration: 0.4, bounce: 0 }}
                >
                    <div className="AccordionPanel">{children}</div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export { Accordion, AccordionItem, AccordionHeader, AccordionPanel };